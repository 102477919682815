
function Alerts()
{
	return (<>
		<div className="attention"></div>
		<div className="alerts"><div className="alert">OOF</div></div>
	</>);
}

export default Alerts;
