
function PopoutNavigation()
{
	return (<>
		<div className="popoutnavigation noselect">

		<div className="inner-frame container">
			<span className="ri-close-line close-popoutnavigation"></span>
			<ul>
			<li><a href="/">Home</a></li>
			<li><a href="/events">Events</a></li>
			<li><a href="/news">News</a></li>
			<li><a href="/partners">Partners</a></li>
			<li><a href="/about">About</a></li>
			<li><a href="/contact">Contact</a></li>
			</ul>
		</div>

		<div className="bottom-frame">
			<span className="ri-twitter-line" style={{color: '#27A4FD'}}></span>
			<span className="ri-instagram-line" style={{color: '#27A4FD'}}></span>
			<span className="ri-facebook-circle-line" style={{color: '#27A4FD'}}></span>
		</div>

		</div>
	</>);
}

export default PopoutNavigation;
