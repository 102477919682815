import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

function App()
{
	const [socket, setSocket] = useState(null);

	useEffect(() => {
		const APIURL = "https://api.ampupradio.com/v2";
		const newSocket = io(APIURL);
		setSocket(newSocket);
		console.log("CAmpUpRadioApp::InitialiseOnceAtStartup - Connected to " + APIURL);
		return () => newSocket.close();
	}, [setSocket]);

	return (<>
		<div className="App" style={{zIndex: '9999', padding: '10px'}}>
		{ socket ? (
			<p>Connected</p>
      ) : (
        <p>Not Connected</p>
      )}
		</div>
	</>);
}

export default App;
