import React, { Component } from 'react';

const title = "DJ Khaled, Justin Bieber, Quavo, Chance THE Rapper...";
const album = "SO AM I (THE DEF NOT A REMIX REMIX)";

function formatNumber(a)
{
	let s = "";
	if (a < 10)
		s = "0";
	s += a;
	return s;
}

export default class Player extends Component
{
	componentDidMount()
	{
		setInterval(() => {
			this.state.seconds++;
			if (this.state.seconds >= 60)
			{
				this.state.seconds = 0
				this.state.mins++;
				if (this.state.mins >= 60)
				{
					this.state.mins = 0;
					this.state.hours++;
				}
			}
			this.setState({});
		},1000);
	}

	state = {curTime:this.getTime(),hour:0,mins:18,seconds:32};

	getTime()
	{
		let today = new Date();
		return formatNumber(today.getHours()) + ':' + formatNumber(today.getMinutes()) + ':' + formatNumber(today.getSeconds());
	}

	render()
	{
		return (<>
			<div className="innerplayer">
				<div className="artwork"></div>
				<div className="button-container">
					<div className="buttonbg"></div>
					<div className="button"><span className="ri-play-fill"></span></div>
				</div>
				<div className="infos">
					<p>{title}</p>
					<p className="album">{album}</p>
					<p className="time">{formatNumber(this.state.hour)}:{formatNumber(this.state.mins)}:{formatNumber(this.state.seconds)}</p>
				</div>
			</div>
		</>);
	}
}
