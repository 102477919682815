import AURPauseButton from '../img/AURPauseButton.svg';

class CAmpUpRadioApp
{
	constructor()
	{
		console.log("CAmpUpRadioApp::CAmpUpRadioApp");

		this.m_player = null;
		this.m_playing = false;
		this.m_images = [];

		this.PrecacheImage(AURPauseButton.toString());

		this.m_attention = document.querySelector('.attention');
		this.m_msg = document.querySelector('.alert');
		this.m_navigation = document.querySelector('.navigation');
		this.m_popoutnavigation = document.querySelector('.popoutnavigation');
		this.m_playButton = document.querySelector('.player .button');
		this.m_fullscreenplayer = document.querySelector('.fullscreenplayer');
		this.m_logo = document.querySelector(".navigation .logo");

		this.m_attention.addEventListener('click', () => {
			this.m_attention.style.visibility = "hidden";
			this.m_msg.style.visibility = "hidden";
			this.m_popoutnavigation.style.visibility = "hidden";
			//this.m_playButton.classList.remove("playing");
		});

		document.querySelector('.hamburger').addEventListener('click', () => {
			this.ShowPopoutNavigation(true);
		});

		document.querySelector('.close-popoutnavigation').addEventListener('click', () => {
			this.ShowPopoutNavigation(false);
		});

		this.m_playButton.addEventListener('click', (ev) => {
			if (!this.m_playing)
			{
				this.InitialisePlayerOnce();
				this.m_player.muted = false;
				this.m_playing = true;
				this.m_playButton.classList.add("playing");
				this.m_playButton.children[0].classList.remove("ri-play-fill");
				this.m_playButton.children[0].classList.add("ri-pause-mini-fill");
			}
			else
			{
				this.m_player.muted = true;
				this.m_playing = false;
				this.m_playButton.classList.remove("playing");
				this.m_playButton.children[0].classList.remove("ri-pause-mini-fill");
				this.m_playButton.children[0].classList.add("ri-play-fill");
			}
			ev.stopPropagation();
		});

		document.querySelector('.innerplayer').addEventListener('click', () => {
			this.SetFullScreen(true);
		});

		document.querySelector('.fullscreenplayer').addEventListener('click', () => {
			this.SetFullScreen(false);
		});

		this.SetFullScreen(true);
	}

	InitialisePlayerOnce()
	{
		if (this.m_player != null)
			return;

		console.log("CAmpUpRadioApp::InitialisePlayerOnce()");

		this.m_player = new Audio("https://api.ampupradio.com:8443/TOP40.mp3");
		this.m_player.volume = 0.75;
		this.m_player.muted = true;

		this.m_player.addEventListener("canplay", event => {
			this.m_player.play();
		});
	}

	PrecacheImage(path)
	{
		console.log("CAmpUpRadioApp::PrecacheImage(\"" + path + "\")");

		if (document.images)
		{
			let image = new Image();
			image.src = path;
			this.m_images.push(image);
		}
	}

	oof()
	{
		this.m_attention.style.visibility = "visible";
		this.m_msg.style.visibility = "visible";
	}

	ShowPopoutNavigation(bEnabled)
	{
		console.log("CAmpUpRadioApp::ShowPopoutNavigation(" + (bEnabled? "true" : "false") + ")");

		if (bEnabled)
		{
			this.m_attention.style.visibility = "visible";
			this.m_popoutnavigation.style.visibility = "visible";
		}
		else
		{
			this.m_attention.style.visibility = "hidden";
			this.m_msg.style.visibility = "hidden";
			this.m_popoutnavigation.style.visibility = "hidden";
		}
	}

	SetFullScreen(bEnabled)
	{
		console.log("CAmpUpRadioApp::SetFullScreen(" + (bEnabled? "true" : "false") + ")");

		if (bEnabled)
		{
			this.m_navigation.classList.remove("dark-navigation");
			this.m_popoutnavigation.classList.add("popoutnavigation-fullscreen");
			this.m_fullscreenplayer.classList.remove("fullscreenplayer-hidden");
			this.m_logo.classList.add("logo-fullscreen");
		}
		else
		{
			this.m_navigation.classList.add("dark-navigation");
			this.m_popoutnavigation.classList.remove("popoutnavigation-fullscreen");
			this.m_fullscreenplayer.classList.add("fullscreenplayer-hidden");
			this.m_logo.classList.remove("logo-fullscreen");
		}
	}
}

window.addEventListener('load', (event) => {
	window.AmpUpRadio = new CAmpUpRadioApp();
});
