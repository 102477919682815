import React, { Component } from 'react';

const title = "DJ Khaled, Justin Bieber, Quavo, Chance THE Rapper...";
const album = "SO AM I (THE DEF NOT A REMIX REMIX)";

export default class FullScreenPlayer extends Component
{
	componentDidMount()
	{
	}

	render()
	{
		return (<>
			<div className="fullscreenplayer fullscreenplayer-hidden noselect">
				<span className="bg"></span>
				<div className="inner-player">
					<div class="inner-frame container">
						<div className="artwork"></div>
						<div className="infos">
							<p>{title}</p>
							<p className="album">{album}</p>
						</div>
					</div>
					<div className="bottom-frame controls">
					</div>
					<div className="bottom-frame button-controls">
						<span class="ri-play-list-fill playlist"></span>
						<div className="button"><span className="ri-play-fill"></span></div>
						<span class="ri-volume-up-line volume"></span>
					</div>
				</div>
			</div>
		</>);
	}
}
