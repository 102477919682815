
function Branding()
{
	return (<>
		<div className="branding noselect">
		<div className="logo"></div>
		<div className="subtext">Your music, your station.</div>
		</div>
	</>);
}

export default Branding;
