import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// AUR stuff
import App from './components/App';
import Alerts from './components/Alerts';
import PopoutNavigation from './components/PopoutNavigation';
import Branding from './components/Branding';
import Navigation from './components/Navigation';
import Player from './components/Player';
import FullScreenPlayer from './components/FullScreenPlayer';

import './css/fonts.css';
import './css/main.css';
import './css/navigation.css';
import './css/player.css';
import './css/alerts.css';
import './css/popoutnavigation.css';
import './css/branding.css';
import './css/fullscreenplayer.css';

import './js/app.js';

ReactDOM.render(
	<React.StrictMode>
		<Alerts />
		<PopoutNavigation />
		<FullScreenPlayer />

		<div className="parent">
			<div className="container">
				<Navigation />
				<div className="content">
				</div>
			</div>

			<Branding />

			<div class="player child"><Player /></div>

			<App />
		</div>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
