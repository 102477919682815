
function Navigation()
{
	return (<>
		<header>
		<div className="navigation dark-navigation noselect">
		<span className="hamburger"><span className="ri-menu-2-line"></span></span>
		<span className="logo"><span className="text">AmpUpRadio</span></span>
		<ul>
		<li><a href="#">Home</a></li>
		</ul>
		</div>
		</header>
	</>);
}

export default Navigation;
